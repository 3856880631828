<template>
  <div>
    <h3 class="is-size-6 p-3">
      {{ stringsInsurance.claim_per_day_title }}
    </h3>
    <table-ui
      class="mb-0"
      :head="{
        date: { text: stringsInsurance.claim_date_title, classes: 'px-3' },
        claim: {
          text: stringsInsurance.claim_value_title,
          align: 'right',
          classes: 'px-3'
        },
      }"
    >
      <template v-slot:body>
        <tr v-for="(item, i) in items" :key="i">
          <td colspan="2" class="px-0">
            <div class="px-3">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <div>{{ $moment(item.date).format('DD MMM YYYY') }}</div>
                  <div class="wet-note">
                    {{ strings.commonApp && strings.commonApp.weather_type[item.type] + ':' }}
                    &nbsp;
                    <span v-if="!item.needs_to_be_checked
                    && item.threshold !== null">
                      {{item.threshold}}{{strings.commonApp && strings.commonApp.unit[item.type]}}
                    </span>
                    <span v-if="!item.needs_to_be_checked
                    && item.threshold !== null && item.status === 'o'">
                      {{stringsInsurance.data_not_complete}}
                    </span>
                    <span v-if="!item.needs_to_be_checked && item.threshold == null"
                          :title="strings.commonApp && strings.commonApp.not_available">
                      {{ stringsInsurance.no_measurement }}
                    </span>
                    <span v-if="!item.needs_to_be_checked && item.status === 'p'">
                      {{ format(stringsInsurance.data_updates_tpl, item.lastHour) }}
                    </span>
                    <span v-if="item.needs_to_be_checked">
                      {{ stringsInsurance.data_needs_to_be_checked}}
                    </span>
                  </div>
                </div>
                <div v-if="item.claim !== null && item.status !== 'o'"
                     class="flex-shrink-1 font-weight-bold">
                  <money-field :value="item.claim" :dynamic-cents="true" :currency="currency" />
                </div>
              </div>
              <div class="text-right font-weight-bold wet-note px-3 pb-3">
                <div v-if="item.id">Claim ID: {{ item.id }}</div>
                <div v-if="item.noClaim && item.status !== 'o'">{{ reason(item.days) }}</div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </table-ui>
  </div>
</template>
<script>
import common from '@/mixins/common';
import tableUi from '@/components/ui/tableUi.vue';
import { mapState } from 'vuex';

export default {
  mixins: [common],
  components: {
    tableUi,
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
  },
  computed: {
    ...mapState(['strings']),
    stringsInsurance() {
      return this.strings.insurance || {};
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '',
    },
  },
  methods: {
    reason(days) {
      return this.format(this.strings.insurance?.no_claim, days);
    },
    checkNeeded() {
      console.log('here');
      return 'Test';
    },
  },
};
</script>
