<template>
  <div class="mr-4 ml-4">
    <p class="m-4" style="color: var(--primary); font-weight: bold">
      {{claimText}}
    </p>
    <first-name-last-name
      ref="firstNameLastName"
      :first-name-place-holder="firstNamePlaceHolder"
      :first-name-initial="firstNameInitial"
      :last-name-place-holder="lastNamePlaceHolder"
      :last-name-initial="lastNameInitial"
      :error-first-name="errorFirstName"
      :error-last-name="errorLastName"
      @change-firstname="(value) => this.firstname = value"
      @change-lastname="(value) => this.lastname = value"
      @initial-error="(value) =>this.disabledNames = !value"
      :disable-input="disabledNames"
    />
    <div class="row no-gutters">
      <div class="col">
        <div class="wet-input wet-input-with-label">
          <input
            id="wet-input-additional-first-names"
            type="text"
            :placeholder="additionalFirstnamesPlaceHolder"
            v-model.trim="additionalFirstnames"
          >
          <label for="wet-input-additional-first-names">
            {{ additionalFirstnamesPlaceHolder }}
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col wet-input-with-label">
        <datepicker-component
          id="datepicker-birthday"
          calId="datepicker-birthday"
          :required="true"
          :language="languages[lang]"
          :value="birthday"
          calendar-class="datepicker"
          wrapper-class="transparent-border"
          :monday-first="true"
          @input="(v) => changeBirthday(v)"
        />
        <label for="datepicker-birthday" class="datepicker-label">
          {{birthdayLabel}}
        </label>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <div class="wet-input wet-input-with-label"
             :class="$v.birthPlace.$error ? 'wet-input-error' : ''"
        >
          <input
            id="wet-input-additional-birth-place"
            type="text"
            :placeholder="birthPlacePlaceHolder"
            v-model.trim="$v.birthPlace.$model"
          >
          <label for="wet-input-additional-birth-place">
            {{ birthPlacePlaceHolder }}
          </label>
        </div>
        <div v-if="$v.birthPlace.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ errorBirthPlace }}
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <div class="wet-input wet-input-with-label"
             :class="$v.payoutIban.$error ? 'wet-input-error' : ''"
        >
          <input
            id="wet-input-additional-payout-iban"
            type="text"
            :placeholder="payoutIbanPlaceHolder"
            v-model.trim="$v.payoutIban.$model"
          >
          <label for="wet-input-additional-birth-place">
            {{ payoutIbanPlaceHolder }}
          </label>
        </div>
        <div v-if="$v.payoutIban.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ errorPayoutIban }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirstNameLastName from '@/components/vacation-buy/firstNameLastName.vue';
import {
  firstname,
  euIban,
  chLiIban,
  lastname,
  notEmpty,
} from '@/common/validations';
import LogService from '@/services/LogService';
import { en, de, pl } from 'vuejs-datepicker/dist/locale';
import configEnv from '@/enums/configEnv';

export default {
  name: 'ClaimsForm',
  components: { FirstNameLastName },
  emits: [
    'change-firstname',
    'change-lastname',
    'change-additional-firstnames',
    'change-birth-place',
    'change-birthday',
    'change-payout-iban',
  ],
  props: {
    firstNamePlaceHolder: {
      type: String,
      required: true,
    },
    lastNamePlaceHolder: {
      type: String,
      required: true,
    },
    firstNameInitial: {
      type: String,
      required: true,
    },
    lastNameInitial: {
      type: String,
      required: true,
    },
    additionalFirstnamesPlaceHolder: {
      type: String,
      required: true,
    },
    birthPlacePlaceHolder: {
      type: String,
      required: true,
    },
    birthdayLabel: {
      type: String,
      required: true,
    },
    payoutIbanPlaceHolder: {
      type: String,
      required: true,
    },
    errorPayoutIban: {
      type: String,
      required: true,
    },
    errorBirthPlace: {
      type: String,
      required: true,
    },
    errorFirstName: {
      type: String,
      required: true,
    },
    errorLastName: {
      type: String,
      required: true,
    },
    claimText: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    initialBirthday: {
      type: String,
      required: true,
    },
  },
  watch: {
    initialBirthday(newInitialBirthday) {
      this.birthday = this.$moment(newInitialBirthday, 'YYYY-MM-DD', true).toDate();
    },
    firstNameInitial(NewFirstNameInitial) {
      this.firstname = NewFirstNameInitial;
      this.$refs.firstNameLastName.$v.$touch();
    },
    lastNameInitial(NewLastNameInitial) {
      this.lastname = NewLastNameInitial;
      this.$refs.firstNameLastName.$v.$touch();
    },
    firstname(newFirstname) {
      this.$emit('change-firstname', newFirstname);
    },
    lastname(newLastname) {
      this.$emit('change-lastname', newLastname);
    },
    birthPlace(newValue) {
      LogService.log('change-birth-place', newValue);
      this.$emit('change-birth-place', newValue);
    },
    additionalFirstnames(newAdditionalFirstnames) {
      LogService.log('change-additional-firstnames', newAdditionalFirstnames);
      this.$emit('change-additional-firstnames', newAdditionalFirstnames);
    },
    payoutIban(newIban) {
      LogService.log('change-payout-iban', newIban);
      this.$emit('change-payout-iban', newIban.replace(/\s/g, ''));
    },
  },
  data() {
    return {
      firstname: this.firstNameInitial,
      lastname: this.lastNameInitial,
      additionalFirstnames: undefined,
      birthPlace: undefined,
      birthday: this.$moment('1980-01-01', 'YYYY-MM-DD', true).toDate(),
      payoutIban: undefined,
      disabledNames: true,
      languages: {
        en,
        de,
        pl,
      },
    };
  },
  methods: {
    changeBirthday(newValue) {
      this.birthday = newValue;
      LogService.log('change-birthday', this.$moment(newValue).format('YYYY-MM-DD'));
      this.$emit('change-birthday', this.$moment(newValue).format('YYYY-MM-DD'));
    },
    getIbanValidationRule() {
      if (configEnv.iban_validation_rule === '1') {
        return euIban;
      }
      return chLiIban;
    },
  },
  validations() {
    return {
      payoutIban: this.getIbanValidationRule(),
      firstname,
      lastname,
      birthPlace: notEmpty,
    };
  },
};
</script>

<style scoped lang="scss">
.vdp-datepicker {
  background: #FFFFFF;
  display: flex;
  position: relative;
  border: 1px solid var(--border-input-color);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: calc(2rem - 2px);
  line-height: 1rem;
  padding: 0 var(--padding-horizontal-input-fields);
  box-sizing: border-box;
  transition: all .3s ease-in-out;
  margin-bottom: 1rem;
}

.datepicker-label {
  position: absolute;
  pointer-events: none;
  background-color: white;
  padding: 0 .5rem;
  top: -.5rem;
  left: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: .75rem;
  line-height: .75rem;
  transition: all .3s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 2rem);
  border-radius: 1rem;
}
</style>
